import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "~/_metronic/layout";
import { BuilderPage } from "~/app/pages/BuilderPage";
import { DashboardPage } from "~/app/pages/DashboardPage";
import User from "~/app/modules/User/User"
import Users from "~/app/modules/Users/Users"
import BannedUsers from "~/app/modules/BannedUsers/BannedUsers"
import ReportBuilder from '~/app/pages/ReportBuilder';
import HighReversalRateBans from '~/app/modules/HighReversalRateBans/HighReversalRateBans';
import VeriffLog from '~/app/modules/VeriffLog/VeriffLog';
import Surveys from '~/app/modules/Surveys/Surveys';
import Survey from '~/app/modules/Survey/Survey';
import Qualifications from '~/app/modules/Survey/components/Qualifications';
import Quotas from '~/app/modules/Survey/components/Quotas';
import SsiRexQuotasAndFilters from '~/app/modules/Survey/components/SsiRexQuotasAndFilters';
import SurveyRawData from '~/app/modules/Survey/components/SurveyRawData';
import TargetedUsers from '~/app/modules/Survey/components/TargetedUsers';
import APILog from '~/app/modules/APILog/APILog';
import ClicksReport from '~/app/modules/ClicksReport/ClicksReport';
import SharedView from '~/app/modules/SharedView/SharedView';
import MiscellaneousExportUserData from '~/app/modules/Miscellaneous/ExportUserData/ExportUserData';
import QAHelpers from '~/app/modules/Miscellaneous/QAHelpers/QAHelpers';
import Translations from "~/app/modules/Translations/Translations";
import Languages from "~/app/modules/Translations/components/Languages/Languages";
import Translators from "~/app/modules/Translations/components/Translators/Translators";
import TranslatorPayments from "~/app/modules/Translations/components/TranslatorPayments";
import Products from "~/app/modules/Translations/components/Products/Products";
import Features from "~/app/modules/Translations/components/Features/Features";
import Feature from "~/app/modules/Translations/components/Feature/Feature";
import Keys from "~/app/modules/Translations/components/Keys/Keys";
import QuestionsManagement from '~/app/modules/QuestionsManagement/QuestionsManagement';
import Questions from '~/app/modules/QuestionsManagement/components/Questions/Questions';
import ProfilerOrder from '~/app/modules/QuestionsManagement/components/ProfilerOrder/ProfilerOrder';
import MappedQuestion from '~/app/modules/QuestionsManagement/components/MappedQuestion/MappedQuestion';
import Reviews from '~/app/modules/Reviews/Reviews';
import SurveyListResultsLog from "~/app/modules/SurveyListResultsLog/SurveyListResultsLog";
import SurveyUpdatesLog from "~/app/modules/SurveyUpdatesLog/SurveyUpdatesLog";
import AmazonInventory from '~/app/modules/Rewards/components/AmazonInventory/AmazonInventory';
import BitcoinRecords from '~/app/modules/Rewards/components/BitcoinRecords/BitcoinRecords';
import RewardsManagement from '~/app/modules/Rewards/components/RewardsManagement/RewardsManagement';
import RewardsDetails from '~/app/modules/Rewards/components/RewardsManagement/RewardsDetails';
import Orders from '~/app/modules/Orders/Orders';
import TolunaIpesData from "./modules/Survey/components/TolunaIPES";
import ProdegeQuotas from "./modules/Survey/components/Prodege";
import TolunaNotificationsLog from "./modules/TolunaNotificationsLog/TolunaNotificationsLog";
import AtomsManagement from "./modules/AtomsManagement/AtomsManagement";
import EmailLimitsManagement from "./modules/EmailLimitsManagement/EmailLimitsManagement";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path='/report-builder' component={ReportBuilder} />
        <ContentRoute path='/clicks/survey/:surveyId?' component={ClicksReport} />
        <ContentRoute path='/clicks/clickId/:clickId?' component={ClicksReport} />
        <ContentRoute path='/clicks/:userId?' component={ClicksReport} />
        <ContentRoute exact path='/users' component={Users} />
        <ContentRoute exact path='/banned-users' component={BannedUsers} />
        <ContentRoute path='/users/:userId' component={User} />
        <ContentRoute path='/banned-users/:userId' component={User} />
        <ContentRoute exact path='/rewards-management' component={RewardsManagement} />
        <ContentRoute exact path='/rewards-management/cardId/:cardId?' component={RewardsManagement} />
        <ContentRoute exact path='/rewards-management/:rewardId' component={RewardsDetails} />
        <ContentRoute path='/amazon-inventory' component={AmazonInventory} />
        <ContentRoute path='/bitcoin-records' component={BitcoinRecords} />
        <ContentRoute exact path='/orders' component={Orders} />
        <ContentRoute path='/orders/userId/:userId?' component={Orders} />
        <ContentRoute exact path='/surveys' component={Surveys} />
        <ContentRoute exact path='/surveys/surveyGroupsIds/:groupId' component={Surveys} />
        <ContentRoute exact path='/surveys/:surveyId' component={Survey} />
        <ContentRoute path='/surveys/:surveyId/qualifications' component={Qualifications} />
        <ContentRoute path='/surveys/:surveyId/raw-data' component={SurveyRawData} />
        <ContentRoute path='/surveys/:surveyId/targeted-users' component={TargetedUsers} />
        <ContentRoute path='/surveys/:surveyId/quotas' component={Quotas} />
        <ContentRoute path='/surveys/:surveyId/ssirex-quotas-filters' component={SsiRexQuotasAndFilters} />
        <ContentRoute path='/surveys/:surveyId/toluna-ipes-details' component={TolunaIpesData} />
        <ContentRoute path='/surveys/:surveyId/prodege-quotas' component={ProdegeQuotas} />
        <ContentRoute path='/shared/:linkId/:pageType' component={SharedView} />
        <ContentRoute path='/questionsManagement' component={QuestionsManagement} />
        <ContentRoute exact path='/questions' component={Questions} />
        <ContentRoute path='/questions/:questionId' component={MappedQuestion} />
        <ContentRoute exact path='/profiler-order' component={ProfilerOrder} />
        <ContentRoute path='/high-reversal-rate-bans' component={HighReversalRateBans} />
        <ContentRoute path='/survey-list-results-log' component={SurveyListResultsLog} />
        <ContentRoute path='/survey-updates-log' component={SurveyUpdatesLog} />
        <ContentRoute path='/veriff-log/:userId?'>
          <VeriffLog />
        </ContentRoute>
        <ContentRoute path='/api-log' component={APILog} />
        <ContentRoute path='/miscellaneous/export-user-data' component={MiscellaneousExportUserData} />
        <ContentRoute path='/miscellaneous/qa-helpers' component={QAHelpers} />
        <ContentRoute path='/toluna-notifications-log' component={TolunaNotificationsLog} />
        <ContentRoute exact path='/translations' component={Translations} />
        <ContentRoute exact path='/translations/languages' component={Languages} />
        <ContentRoute exact path='/translations/translators' component={Translators} />
        <ContentRoute exact path='/translations/translator-payments' component={TranslatorPayments} />
        <ContentRoute exact path='/translations/products' component={Products} />
        <ContentRoute exact path='/translations/features' component={Features} />
        <ContentRoute exact path='/translations/features/:featureId' component={Feature} />
        <ContentRoute exact path='/translations/keys' component={Keys} />
        <ContentRoute exact path='/reviews' component={Reviews} />
        <ContentRoute path='/atoms-management' component={AtomsManagement} />
        <ContentRoute path='/email-limtis-management' component={EmailLimitsManagement} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
